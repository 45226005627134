$spacing-major: 14px;
$spacing-minor: 7px;

$header-height: 60px;

div.page-content {
  margin-top: calc($header-height + env(safe-area-inset-top));
}

ion-content.page-content {
  // This doesn't work and I don't know why but I think I'd like it to work?
  --offset-top: #{$header-height};
}
