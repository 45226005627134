@use '../layout.scss' as layout;

/* Old styles - for backwards compat */
.card-header {
  height: 3.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  background-color: var(--ion-color-dark-hue);
  padding-left: 16px;
  padding-right: 16px;

  .header-text {
    font-size: 18px;
    font-weight: bold;
    color: white;
    // to truncate long title
    display: -webkit-box !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
  }
}

/* New styles */
// This is prefixed with "el" just to make sure it stays isolated from other libraries' card styles
.el-card {
  box-shadow: 6px 6px 5px var(--ion-color-light-shade);

  .el-card-header {
    height: 3.5em;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
    background-color: var(--ion-color-dark-hue);
    padding-left: layout.$spacing-major;
    padding-right: layout.$spacing-major;
    color: white;

    .el-card-header-text {
      font-size: 18px;
      font-weight: bold;
      // to truncate long title
      display: -webkit-box !important;
      max-width: 100% !important;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 1 !important;
    }
  }

  .el-card-content, * > .el-card-content {
    padding: layout.$spacing-major;
    background-color: var(--ion-color-light);
  }
}
