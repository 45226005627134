.content-sticky-footer {
  position: fixed;
  bottom: 0;
  height: auto;
  width: 100%;
  background-color: var(--ion-color-light);
  z-index: 99999;
}

.bottom-bkp-09 {
  bottom: 10%;
}

.bottom-bkp-08 {
  bottom: 20%;
}

.bottom-bkp-075 {
  bottom: 25%;
}

.bottom-bkp-07 {
  bottom: 30%;
}

.bottom-bkp-06 {
  bottom: 40%;
}

.bottom-bkp-05 {
  bottom: 50%;
}

.footer-container {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
}

.action-button-container {
  display: flex;
  flex-direction: row;
}

.primary-button,
.primary-button-footer {
  height: 50px;
  --background: var(--eventlink-primary);
  --background-activated: darken(var(--eventlink-primary), 25%);
  flex: 1;
}

.secondary-button,
.secondary-button-footer {
  height: 50px;
  --background: var(--eventlink-secondary);
  --background-activated: darken(var(--eventlink-secondary), 25%);
  flex: 1;
}

.tertiary-button,
.tertiary-button-footer {
  height: 50px;
  --background: var(--eventlink-tertiary);
  --background-activated: darken(var(--eventlink-tertiary), 25%);
  flex: 1;
}
