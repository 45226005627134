@use '@mobiscroll/angular/dist/css/mobiscroll.scss';
mbsc-eventcalendar {
  mbsc-calendar-nav {
    mbsc-button {
      span {
        font-size: medium !important;
      }
    }
  }
  mbsc-event-list-item {
    margin-top: 0 !important;
  }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: pre-line;
}

.alert-message.sc-ion-alert-md {
  white-space: pre-line;
}
