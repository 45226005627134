/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Eventlink customizations */
@use 'theme/layout.scss' as layout;
@use 'theme/typography.scss';

/* Eventlink components */
@use 'theme/components/cards.scss';
@use 'theme/components/sticky-footer.scss';

/* Vendors */
@use 'theme/vendor/mobiscroll.scss';

/* Global vars */
@use 'theme/variables.scss';

:root {
  --eventlink-header-height: #{layout.$header-height};
}

// ios bottom iphone x fixed
.ios {
  ion-header {
    --margin-bottom: calc(58px + env(safe-area-inset-top));
  }

  ion-content {
    --padding-top: var(–ion-safe-area-top, 0);
    --padding-bottom: var(–ion-safe-area-bottom, 0);
  }

  ion-tab-bar {
    margin-bottom: calc(7px + env(safe-area-inset-top));
  }

  ion-button {
    text-transform: uppercase;
  }

  body {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  }
}

ion-segment-button {
  text-wrap: wrap;
}

ion-content {
  --ion-background-color: var(--ion-color-light-grey);
}

ion-checkbox {
  --size: 24px;
  --border-color-checked: var(--eventlink-secondary);
  --checkbox-background-checked: var(--eventlink-secondary);
}

.button-height {
  height: 50px;
}

ion-title {
  &.md {
    width: 100%;
    position: absolute;
    left: 0;
    height: 0;
    text-align: center;
  }
}

.segment-header {
  --background: var(--ion-color-light);
  border-radius: 0;
  height: 50px;

  ion-segment-button {
    height: 100%;

    --indicator-color: var(--ion-color-dark-shade);
    --border-radius: 0;
    --color: var(--ion-color-medium-shade);
    --color-checked: var(--ion-color-dark-shade);
    --border-color: var(--ion-color-dark-shade);
    --border-width: 1px;

    margin: 0;

    &.segment-button-checked {
      font-weight: bold;
    }
  }

  margin-top: calc(#{layout.$header-height} + env(safe-area-inset-top));
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
div[slot='content'] {
  background: rgba(var(--ion-color-light), 0.25);
}

div[slot='header'] {
  background: rgba(var(--ion-color-light), 0.25);
}

ion-modal.auto-height {
  --height: 100%;
}

ion-button fa-icon {
  margin-right: 1em;
  color: var(--ion-color-primary-contrast);
}

body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

.barcode-scanning-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanning-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

/**
  * Text Colors
  * -------------------------------------------
  */

.green-text {
  color: var(--eventlink-primary);
  font-weight: bold;
}

.red-text {
  color: var(--eventlink-tertiary);
  font-weight: bold;
}

.red-italics-text {
  color: var(--eventlink-tertiary);
  font-style: italic;
}

.purple-text {
  color: #b718b0;
  font-weight: bold;
}

.grey-italics-text {
  color: #8e8e8e;
  font-style: italic;
}
