@use '@mobiscroll/angular/dist/css/mobiscroll.scss';
mbsc-eventcalendar {
  mbsc-calendar-nav {
    mbsc-button {
      span {
        font-size: medium !important;
      }
    }
  }
  mbsc-event-list-item {
    margin-top: 0 !important;
  }
}

.mbsc-calendar-button.mbsc-button {
  color: var(--ion-color-dark-shade) !important;
}

.mbsc-ios-dark.mbsc-selected .mbsc-calendar-cell-text {
  border-color: var(--eventlink-primary) !important;
  background: var(--eventlink-primary) !important;
  color: #fff !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  border-color: var(--eventlink-primary) !important;
  background: var(--eventlink-primary) !important;
  color: #fff !important;
}

.mbsc-selected .mbsc-calendar-cell-text {
  border-color: var(--eventlink-primary) !important;
  background: var(--eventlink-primary) !important;
  color: #fff !important;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: pre-line;
}

.alert-message.sc-ion-alert-md {
  white-space: pre-line;
}
