$eventlink-regular-font-family: EventlinkRegular;
$roboto-font-family: Roboto;

@font-face {
  font-family: $eventlink-regular-font-family;
  src: url(../assets/fonts/EventlinkRegular.otf) format("opentype");
}

@font-face {
  font-family: $roboto-font-family;
  src: url(../assets/fonts/Roboto-VariableFont_wdth,wght.ttf) format("truetype-variations");
  font-weight: 100 1000;
}
